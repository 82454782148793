import Api from '@/services/Index';

// Reservation
const checkToken = async (token) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/housing/reservations/detail/' + token);
}
const sendStatus = async (token,formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/housing/reservations/agreement-form/' + token,formData);
}

export default {
    checkToken,
    sendStatus
}
